/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import theme from '../utils/theme';

import Header from './header';
import Footer from './footer';
import mq from '../utils/mq';
import './layout.css';

const BgWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.bgColor || 'inherit'};

  display: grid;
  grid-template-rows: [header] min-content [content] auto [footer] min-content;
`;

const Main = styled.main`
  grid-row-start: content;
  justify-self: center;

  box-sizing: border-box;
  max-width: 21rem;
  padding-left: 1rem;
  padding-right: 1rem;

  ${mq.bpw2} {
    max-width: 24rem;
  }

  ${mq.bpw3} {
    max-width: 28rem;
  }

  ${mq.bpw4} {
    max-width: 48rem;
  }
`;

const Layout = props => {
  const key = props.scheme || 'light';
  const { bgColor } = theme[key];

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <BgWrapper bgColor={bgColor}>
            <Header siteTitle={data.site.siteMetadata.title}></Header>
            <Main>{props.main}</Main>
            <Footer />
          </BgWrapper>
        </ThemeProvider>
      )}
    />
  );
};

Layout.propTypes = {
  left: PropTypes.node,
  main: PropTypes.node.isRequired,
  right: PropTypes.node,
};

export default Layout;
