import React from 'react';
import { css } from '@emotion/react';

const Footer = props => {
  const footerCss = theme => ({
    gridRowStart: 'footer',
    fontSize: '0.7rem',
    color: theme.running.fgColor,
    backgroundColor: theme.running.bgColor,
    padding: '0.2rem 0',
  });

  const pCss = css`
    margin: 0;
    text-align: center;
  `;

  let year = new Date().getFullYear();

  if (year < 2020) {
    year = 2020;
  } else if (year > 2020) {
    year = `2020-${year}`;
  }

  return (
    <footer css={footerCss}>
      <div>
        <p css={pCss}>Copyright &copy; {year}, Rafael Ibraim.</p>
      </div>
    </footer>
  );
};

export default Footer;
