import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import theme from '../utils/theme';
import mq from '../utils/mq';

const Header = styled.header`
  grid-row-start: header;
  color: ${theme.running.color};
  background-color: ${theme.running.bgColor};
`;

const LinkHolder = styled.p`
  margin: 0;
  text-align: center;

  a {
    font-family: 'Press Start 2P';
    font-size: 0.55rem;
    text-transform: uppercase;
    color: ${theme.running.fgColor};
    background-color: ${theme.running.bgColor};
    background-image: none;
    text-shadow: none;
    padding: 0.1rem 0.4rem;
  }

  a::before {
    content: ':';
  }

  a::after {
    content: ':';
  }

  #home {
    &:hover {
      color: ${theme.term.red};
    }
  }

  #blog {
    &:hover {
      color: ${theme.term.yellow};
    }
  }

  #tags {
    display: none;

    ${mq.bpw2} {
      display: inline;
    }

    &:hover {
      color: ${theme.term.purple};
    }
  }

  #about {
    &:hover {
      color: ${theme.term.blue};
    }
  }

  #github {
    &:hover {
      color: ${theme.term.green};
    }
  }
`;

const NavLink = styled(Link)``;

const ExtLink = styled.a``;

export default props => {
  return (
    <Header>
      <div>
        <LinkHolder>
          <NavLink id="home" title="Home" to="/">
            Home
          </NavLink>
          <NavLink id="blog" title="Blog" to="/blog">
            Blog
          </NavLink>
          <NavLink id="about" title="About" to="/about">
            About
          </NavLink>
          <NavLink id="tags" title="Tags" to="/tags">
            Tags
          </NavLink>
          <ExtLink
            id="github"
            title="Github page"
            href="https://github.com/ibraimgm"
          >
            GitHub
          </ExtLink>
        </LinkHolder>
      </div>
    </Header>
  );
};
