/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbySeo, BlogPostJsonLd } from 'gatsby-plugin-next-seo';

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  url,
  published,
  featuredImage,
  schema,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            title
            description
            siteUrl
            image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  let schemaObj = schema ? JSON.parse(schema) : null;

  // image processing
  let images = [];

  if (featuredImage) {
    let featuredUrl =
      site.siteMetadata.siteUrl + featuredImage.childImageSharp.fluid.src;
    images = images.concat(featuredUrl);
  }

  images = images.concat(site.siteMetadata.image);

  // extra fields because google is annoying
  if (schemaObj) {
    schemaObj.headline = schemaObj.name;
    schemaObj.image = images;
    schemaObj.dateModified = schemaObj.datePublished;

    schemaObj.publisher = {
      '@type': 'Organization',
      name: site.siteMetadata.title,
      logo: {
        '@type': 'ImageObject',
        url: site.siteMetadata.image,
      },
    };
  }

  // canonical url
  const canonicalUrl = site.siteMetadata.siteUrl + (url || '');

  return (
    <React.Fragment>
      <GatsbySeo
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        description={metaDescription}
        canonical={canonicalUrl}
        openGraph={{
          url: canonicalUrl,
          type: `website`,
          title: title,
          description: description,
          images: images.map(v => {
            return { url: v };
          }),
          article: {
            authors: [site.siteMetadata.author],
            tags: keywords,
          },
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
        metaTags={[{ name: 'author', content: site.siteMetadata.author }]}
      />
      {schemaObj && <BlogPostJsonLd overrides={schemaObj} />}
    </React.Fragment>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
