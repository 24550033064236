export default {
  // width breakpoints
  bpw1: '@media(min-width: 22rem)',
  bpw2: '@media(min-width: 25rem)',
  bpw3: '@media(min-width: 33rem)',
  bpw4: '@media(min-width: 48rem)',

  // height breakpoints
  bph1: '@media(min-height: 40rem)',
  bph2: '@media(min-height: 50rem)',
  bph3: '@media(min-height: 62rem)',

  // corner-cases, that need both width and height
  bpwh1: '@media (min-height: 45rem) and (min-width: 25rem)',
};
