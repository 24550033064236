import Typography from 'typography';
import fairyGateTheme from 'typography-theme-fairy-gates';

fairyGateTheme.googleFonts = [];
fairyGateTheme.headerFontFamily = ['Open Sans Condensed', 'sans-serif'];
fairyGateTheme.bodyFontFamily = ['Droid Sans', 'sans-serif'];

const typography = new Typography(fairyGateTheme);

export const { scale, rhythm, options } = typography;

export default typography;
