import typography from './typography';

export default {
  /* applyed to both header and footer */
  running: {
    bgColor: 'rgb(26, 43, 52)',
    fgColor: 'rgba(255,255,255,0.8)',
  },

  /* terminal-like colors */
  term: {
    black: '#000000',
    green: '#4af626',
    yellow: '#f8bc45',
    purple: '#f629ff',
    blue: '#12eaea',
    red: '#ff373a',
  },

  /* applyed to the 'dark' scheme */
  dark: {
    bgColor: 'rgb(24,32,37)',
    fgColor: 'rgba(255,255,255,0.8)',
    greenLink: '#1ca086',
  },

  /* normal 'blog' scheme */
  light: {
    bgColor: '#ffffff',
    fgColor: typography.options.bodyColor,
    underline: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1px, ${typography.options.bodyColor} 1px, ${typography.options.bodyColor} 2px, rgba(0, 0, 0, 0) 2px )`,
  },
};
